import { Link } from "gatsby";
import React from "react";
import { Container } from "../styles/UI";
import { navigate } from "gatsby";
import Layout from "../components/Layout";
import Seo from "../components/Seo";
import styled from "styled-components";

import taxcelBgLogo from "../images/home/logo-negative.svg";
import Button from "../DesignSystem/Button";

const Hero = styled.section`
  width: 100%;
  background: ${(p) => p.theme.degradeAzul};
  margin-top: -180px;
  padding-top: 180px;
  padding-bottom: 120px;
  color: var(--color-white);
  box-sizing: border-box;
  overflow: hidden;

  .buttons {
    button {
      margin-top: 16px;
    }
    @media (min-width: ${(p) => p.theme.bp.tablet}) {
      display: flex;
      align-items: center;

      button:first-child {
        margin-right: 24px;
      }
    }
  }
  /* 
  button:last-child:hover {
    background: var(--color-white);
  }

  button:last-child {
    box-shadow: 0px 8px 16px -4px #05185b;
  } */

  ${Container} {
    .cta {
      position: relative;
      z-index: 10;

      h1 {
        font-size: 30px;
        font-weight: 600;
        letter-spacing: -0.01em;
        margin-bottom: 24px;
        line-height: 1.25;
      }

      p {
        font-weight: 350;
        font-size: 20px;
        line-height: 32px;
        margin-bottom: 24px;
      }
    }

    .illustration {
      z-index: 1;
      margin-top: 0px;
      position: relative;

      .bg-logo {
        position: absolute;
        z-index: 0;
        height: 800px;
        right: -180px;
        top: -240px;

        @media (min-width: ${(p) => p.theme.bp.tablet}) {
          left: -100px;
          top: -580px;
          height: 1360px;
        }
      }
    }

    @media (min-width: ${(p) => p.theme.bp.tablet}) {
      max-height: 820px;
      display: grid;
      /* grid-template-columns: repeat(2, 1fr); */
      grid-template-columns: minmax(600px, 1fr) 1fr;
      grid-column-gap: 32px;
      align-items: center;

      h1 {
        font-size: 35px;
        margin-top: 64px;
      }
    }
  }
`;

const Politica = styled.section`
  padding: 80px 40px;

  h1 {
    margin-bottom: 80px;
    font-weight: 600;
    font-size: 36px;
    line-height: 56px;
    /* or 123% */

    text-align: center;
    letter-spacing: -1px;
    background: ${(p) => p.theme.degradeMisto2};
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
  @media (min-width: ${(p) => p.theme.bp.tablet}) {
    line-height: 42px;

    span {
      font-size: 50px;
    }
  }

  h2 {
    margin-top: 1.5em;
    margin-bottom: 16px;
    font-weight: 700;
    font-size: 1.75rem;
    color: ${(p) => p.theme.primary};
    letter-spacing: -0.025em;
    text-transform: uppercase;
  }

  h3 {
    margin-top: 1em;
    margin-bottom: 16px;
    font-weight: 700;
    font-size: 1.25rem;
    color: ${(p) => p.theme.primary};
    letter-spacing: -0.025em;
  }

  p {
    margin-bottom: 1rem;
    line-height: 1.3;
    text-align: justify;
  }

  ul {
    list-style-type: disc;
    list-style-position: inside;
    margin-left: 1.25rem;

    li {
      margin-bottom: 1rem;
      line-height: 1.4;
    }
  }

  ol {
    list-style-type: lower-roman;
    list-style-position: inside;
    margin-left: 1.25rem;

    li {
      margin-bottom: 1rem;
      line-height: 1.4;
    }
  }
`;

export default function TermosDeUso() {
  const handleClickTaxDashs = () => {
    navigate("#taxdashs");
  };
  const handleClickTaxSheets = () => {
    navigate("#taxsheets");
  };

  return (
    <Layout>
      <Seo title="Termos e condições de uso" />
      <Hero>
        <Container>
          <div className="cta">
            <h1>Termos e condições de uso</h1>
            <p>
              Este instrumento contém os termos gerais e condições de uso do
              Software disponibilizado pela TAXCEL SOLUÇÕES FISCAIS S.A.,
              sociedade anônima com sede na Rua da Consolação, 2302 -
              Consolação, 4º andar, na Cidade de São Paulo, Estado de São Paulo,
              devidamente inscrita no CNPJ/ME sob o nº. 20.848.861/0001-31
              (“TAXCEL”).
            </p>
            <div className="buttons">
              <Button
                ghost
                bg="secondary"
                color="secondary"
                onClick={handleClickTaxDashs}
              >
                Termos TaxDashs
              </Button>
              <Button
                ghost
                bg="secondary"
                color="secondary"
                onClick={handleClickTaxSheets}
              >
                Termos TaxSheets
              </Button>
            </div>
          </div>
          <div className="illustration">
            <img src={taxcelBgLogo} alt="" className="bg-logo" />
          </div>
        </Container>
      </Hero>
      <Politica id="taxsheets">
        <Container>
          <h1>
            Termos e condições de uso <br />
            <span>TaxSheets</span>
          </h1>

          <h2>PREÂMBULO</h2>

          <p>
            CONSIDERANDO QUE A ACEITAÇÃO DESTES TERMOS E CONDIÇÕES DE USO É
            ABSOLUTAMENTE INDISPENSÁVEL À UTILIZAÇÃO DO SOFTWARE.
          </p>
          <p>
            CONSIDERANDO QUE AO CLICAR NA CAIXA QUE INDICA SUA CONCORDÂNCIA VOCÊ
            ACEITARÁ OS TERMOS, DECLARANDO QUE LEU E CONCORDOU COM TODAS AS
            DISPOSIÇÕES DESTE DOCUMENTO.
          </p>
          <p>
            Considerando que é necessário que o usuário leia o presente Termo de
            Uso, atentando-se para todas as disposições nele contidas, incluindo
            seus pré-requisitos, restrições de uso e outros procedimentos ou
            condições.
          </p>

          <h2>SOBRE O SOFTWARE</h2>

          <p>
            TaxSheets é uma ferramenta capaz de extrair informações dos arquivos
            fiscais e organizar esses dados para serem visualizados, analisados
            e editados no Excel, com isso, o usuário reduz drasticamente
            problemas com: 1) Processos manuais e repetitivos 2) Mudanças de
            regras fiscais 3) Sistemas ERP pouco flexíveis 4) Atrasos, erros e
            multas.
          </p>

          <h2>PRÉ-REQUISITOS PARA UTILIZAÇÃO DO SOFTWARE</h2>

          <p>
            Para que o usuário consiga utilizar a ferramenta é necessário que
            possua:
          </p>
          <ul>
            <li>Windows Server 2012 ou Windows 7 (ou superiores);</li>
            <li>Microsoft Office 2010 ou superior;</li>
            <li>
              Microsoft .NET Framework atualizado (
              <a href="https://www.microsoft.com/pt-br/download/details.aspx?id=49981">
                download
              </a>
              );
            </li>
            <li>
              Caso haja servidor proxy ou VPN será necessário permitir acesso às
              portas 80, 443, também para os aliases
              “https://Taxcellicenseserver2.azurewebsites.net/”,
              “http://Taxcel.com.br/” e permitir o DNS para os servidores Azure;
            </li>
            <li>
              4Gb para máquinas 32 bits, em máquinas 64 bits é recomendado 8 GB
              de memória para arquivos pequenos e médios e 16 GB de memória para
              arquivos grandes;
            </li>
            <li>
              Processador Intel Core i5 para arquivos pequenos e médios ou Intel
              Core i7 para arquivos grandes ou AMD Ryzen para ambos;
            </li>
            <li>Visual Studio 2010 Tools for Office Runtime.</li>
          </ul>

          <h2>USO DO SOFTWARE</h2>

          <p>
            O usuário não poderá utilizar ou permitir o uso do Software para
            qualquer finalidade que viole os termos deste documento, da
            legislação vigente ou de direitos de terceiros.
          </p>
          <p>O usuário não poderá:</p>
          <ol>
            <li>
              alugar, arrendar, emprestar, copiar, modificar, sub-licenciar,
              ceder, transferir, eletronicamente transmitir ou receber o
              Software, exceto nos casos previstos nesta licença, ou conforme
              expressamente indicado pela TAXCEL;
            </li>
            <li>
              traduzir, fazer engenharia reversa, decompilar, desmontar ou
              alterar o Software ou sua documentação;
            </li>
            <li>
              reproduzir, adaptar, modificar e/ou empregar, no todo ou em parte,
              para qualquer fim, o Software ou qualquer conteúdo de propriedade
              da TAXCEL, sem a expressa autorização da TAXCEL.
            </li>
          </ol>
          <p>
            A prestação de código-fonte, se incluído com o Software, não
            constitui transferência de quaisquer direitos legais sobre esse
            código, e de revenda ou distribuição de todo ou parte de todo o
            código fonte e propriedade intelectual serão processados em toda a
            extensão de todas as normas leis locais, federais e internacionais.
            O usuário concorda em tomar todas as medidas razoáveis, legais e
            apropriadas para proibir a divulgação ilegal do Software ou de
            qualquer de suas partes constituintes e distribuíveis em toda a
            extensão de todos os códigos aplicáveis, locais do Brasil e as leis
            internacionais e tratados em matéria de evasão, incluindo, mas não
            se limitando a Convenção de Genebra, Berna e Paris sobre Propriedade
            Intelectual.
          </p>

          <p>
            O usuário poderá: instalar e usar uma cópia do Software, incluindo
            todo e qualquer código fonte, se fornecido, ou qualquer versão
            anterior legalmente licenciada para o mesmo sistema operacional, em
            um único computador, de forma a garantir o uso do software
            exclusivamente para a quantidade de usuários contratada. O usuário
            reconhece e concorda que o Software no formato de código-fonte é um
            segredo comercial confidencial da TAXCEL.
          </p>

          <p>
            As licenças são unitárias anuais, por usuário, limitada a 01 máquina
            por chave de licença, de forma a garantir o uso do software
            exclusivamente para a quantidade de usuário(s)/licença(s)
            contratada(s) ou testada(s).
          </p>

          <p>
            O usuário reconhece e aceita que o estado da técnica e a natureza do
            Software não permitem que o Software funcione de forma ininterrupta
            ou inteiramente livres de defeitos e que, portanto, a TAXCEL não
            pode garantir e não garante que o Software operará sem qualquer
            interrupção ou livre de quaisquer erros ou defeitos e tampouco que o
            Software é adequado para satisfazer necessidades específicas do
            usuário. O Software e qualquer documentação relacionada é FORNECIDO
            "COMO ESTÁ" SEM GARANTIA DE NENHUM TIPO, SEJA EXPRESSA OU IMPLÍCITA,
            INCLUINDO, SEM LIMITAÇÃO, AS GARANTIAS DE COMERCIALIZAÇÃO, ADEQUAÇÃO
            A UM DETERMINADO FIM OU NÃO VIOLAÇÃO. O RISCO TOTAL DECORRENTE DO
            USO OU DESEMPENHO DO SOFTWARE PERMANECE COM O USUÁRIO.
          </p>

          <p>
            Na extensão máxima permitida pela lei aplicável, em nenhuma
            hipótese, a TAXCEL será responsável por quaisquer danos especiais,
            incidentais, indiretos ou consequentes de qualquer natureza
            (incluindo, sem limitação, danos por perda de lucros, interrupção de
            negócios, perda de negócios informação, ou qualquer outra perda
            pecuniária) decorrentes do uso ou da incapacidade de usar o Software
            ou a prestação ou não prestação de serviços de suporte, mesmo que a
            TAXCEL tenha sido avisada da possibilidade de tais danos.
          </p>

          <p>
            Todos os dados e conteúdos inseridos no Software pelos usuários, são
            de única e exclusiva responsabilidade deste.
          </p>

          <p>
            A TAXCEL não tem acesso a nenhuma informação fiscal ou contábil
            inserida pelo usuário no Software, toda e qualquer declaração de
            cunho contábil ou fiscal será de única e exclusiva responsabilidade
            da parte declarante.
          </p>

          <h2>USO DO SUPORTE</h2>
          <p>
            A TAXCEL oferecerá suporte técnico remoto por meio de telefone ou
            e-mail ao usuário. O Suporte estará disponível ao usuário de segunda
            a sexta-feira, das 9h00 às 18h00. O Suporte Técnico é limitado a
            esclarecimentos e informações acerca do Software e sua utilização.
          </p>

          <p>
            Qualquer outro tipo de suporte técnico, incluindo, mas não se
            limitando a suportes locais, instalação, migração ou consultoria
            deverão ser feitos nos termos e condições estipulados pela TAXCEL em
            cada caso.
          </p>

          <h2>CONDIÇÕES GERAIS</h2>
          <p>
            As partes se comprometem a manter e tratar como confidencial, e não
            revelar a terceiros qualquer Informação Confidencial relacionada ao
            Software, a esta Proposta e aos serviços, dados de usuários, segredo
            de indústria e outros.
          </p>

          <p>
            As partes se comprometem a cumprir a Lei 13.709/18 (Lei de Proteção
            de Dados Pessoais - LGPD), bem como regras e regulamentos que lhe
            forem aplicáveis e que tenham por objeto os dados pessoais tratados
            no âmbito dos serviços contratados.
          </p>
          <p>
            Caberá exclusivamente ao usuário responsabilizar-se pela adequação,
            conformidade e cumprimento da legislação no tocante à autorização do
            tratamento dos dados pessoais disponibilizados pelo usuário no
            Software da TAXCEL. Em relação a estes dados, a TAXCEL se enquadra
            como Operadora de dados.
          </p>
          <p>
            Cada Parte será responsável pelo tratamento de dados pessoais por
            ela realizado, mantendo a outra Parte indene de danos ou prejuízos
            decorrentes de qualquer operação de tratamento de dados pessoais
            realizada em desacordo com o Contrato e/ou a legislação aplicável.
          </p>
          <h2>MODIFICAÇÕES DOS TERMOS DE USO</h2>
          <p>
            A TAXCEL se reserva o direito de modificar os Termos e Condições do
            presente documento a qualquer momento, por seu exclusivo critério,
            sendo consideradas efetivadas as alterações após a publicação de uma
            versão atualizada destes Termos no Software, o que se sujeitará ao
            aceite do usuário.
          </p>
          <br />
          <p>
            <b>Documento atualizado em Agosto de 2023</b>
          </p>
        </Container>
      </Politica>
      <Politica id="taxdashs">
        <Container>
          <h1>
            Termos e condições de uso <br />
            <span>TaxDashs</span>
          </h1>

          <h2>Preâmbulo</h2>
          <p>
            Considerando que a aceitação destes Termos e Condições de Uso é
            absolutamente indispensável à utilização do Software.
          </p>
          <p>
            Considerando que ao clicar na caixa que indica sua concordância você
            aceitará os termos, declarando que leu e concordou com todas as
            disposições deste documento.
          </p>
          <p>
            Considerando que é necessário que o usuário leia o presente Termo de
            Uso, atentando-se para todas as disposições nele contidas, incluindo
            seus pré-requisitos, restrições de uso e outros procedimentos ou
            condições.
          </p>

          <h2>Sobre o Software</h2>
          <p>
            TaxDashs (BI para Tax) é uma plataforma que permite transformar os
            arquivos entregues ao fisco em dashboards interativos do Power BI,
            com isso, a ferramenta irá solucionar as seguintes dores:
          </p>
          <ul>
            <li>Descontrole dos relatórios fiscais da sua empresa</li>
            <li>Falta de indicadores para tomada de decisões</li>
            <li>Alto custo e pouco valor agregado do departamento fiscal</li>
            <li>
              Dificuldade em implementar uma solução de data analytics aplicado
              a tax
            </li>
            <li>Atrasos, erros e multas</li>
          </ul>
          <p>
            Visualize as suas informações fiscais em relatórios interativos no
            Power BI e obtenha insights valiosos para sua empresa.
          </p>
          <ul>
            <li>Relatórios prontos para uso e customizáveis</li>
            <li>Dados na nuvem protegidos e criptografados</li>
            <li>Alta performance com grande volume de dados</li>
            <li>Data analytics tributário</li>
            <li>Mapas estratégicos</li>
            <li>Análise comparativa</li>
          </ul>

          <h2>Uso do Software</h2>
          <p>
            O usuário não poderá utilizar ou permitir o uso do Software para
            qualquer finalidade que viole os termos deste documento, da
            legislação vigente ou de direitos de terceiros.
          </p>
          <ol type="a">
            <li>
              alugar, arrendar, emprestar, copiar, modificar, sub-licenciar,
              ceder, transferir, eletronicamente transmitir ou receber o
              Software, exceto nos casos previstos nesta licença, ou conforme
              expressamente indicado pela TAXCEL;
            </li>
            <li>
              traduzir, fazer engenharia reversa, decompilar, desmontar ou
              alterar o Software ou sua documentação;
            </li>
            <li>
              reproduzir, adaptar, modificar e/ou empregar, no todo ou em parte,
              para qualquer fim, o Software ou qualquer conteúdo de propriedade
              da TAXCEL, sem a expressa autorização da TAXCEL.
            </li>
          </ol>
          <p>
            O Software TaxDashs é licenciado na modalidade de serviço ou
            prestação de serviços, executado em um servidor remoto, não sendo
            necessária a instalação do sistema no computador do CONTRATANTE,
            bastando acessá-lo através da internet.
          </p>
          <p>
            A prestação de código-fonte, se incluído com o Software, não
            constitui transferência de quaisquer direitos legais sobre esse
            código, e de revenda ou distribuição de todo ou parte de todo o
            código fonte e propriedade intelectual serão processados em toda a
            extensão de todas as normas leis locais, federais e internacionais.
          </p>
          <p>
            A TAXCEL concorda em tomar todas as medidas razoáveis, legais e
            apropriadas para proibir a divulgação ilegal do Software ou de
            qualquer de suas partes constituintes e distribuíveis em toda a
            extensão de todos os códigos aplicáveis, locais do Brasil e as leis
            internacionais e tratados em matéria de evasão, incluindo, mas não
            se limitando a Convenção de Genebra, Berna e Paris sobre Propriedade
            Intelectual.
          </p>
          <p>
            O usuário reconhece e aceita que o estado da técnica e a natureza do
            Software não permitem que o Software funcione de forma ininterrupta
            ou inteiramente livres de defeitos e que, portanto, a TAXCEL não
            pode garantir e não garante que o Software operará sem qualquer
            interrupção ou livre de quaisquer erros ou defeitos e tampouco que o
            Software é adequado para satisfazer necessidades específicas do
            usuário. O Software e qualquer documentação relacionada é fornecido
            "como está" sem garantia de nenhum tipo, seja expressa ou implícita,
            incluindo, sem limitação, as garantias de comercialização, adequação
            a um determinado fim ou não violação. O risco total decorrente do
            uso ou desempenho do software permanece com o usuário.
          </p>
          <p>
            Na extensão máxima permitida pela lei aplicável, em nenhuma
            hipótese, a TAXCEL será responsável por quaisquer danos especiais,
            incidentais, indiretos ou consequentes de qualquer natureza
            (incluindo, sem limitação, danos por perda de lucros, interrupção de
            negócios, perda de negócios informação, ou qualquer outra perda
            pecuniária) decorrentes do uso ou da incapacidade de usar o Software
            ou a prestação ou não prestação de serviços de suporte, mesmo que a
            TAXCEL tenha sido avisada da possibilidade de tais danos.
          </p>

          <p>
            A TAXCEL envidará todos os melhores esforços para manter o Software
            disponível e online por no mínimo 99,00% (noventa e nove por cento)
            do tempo.
          </p>
          <p>
            As Partes acordam que o compromisso de Nível de Serviço não se
            aplicará a situações que estejam relacionadas com: (i) de uma
            interrupção do fornecimento de energia elétrica ou de internet; (ii)
            situações que não sejam razoavelmente previsíveis pela TAXCEL, como
            casos de força maior ou caso fortuito; (iii) situações que resultem
            de quaisquer atos ou omissões do Contratante ou de terceiros; (iv) o
            equipamento de TI, software e/ou outras tecnologias que o
            Contratante usar que impeçam o acesso regular ao Software; (v)
            manutenções programadas ao Software, que serão comunicadas ao
            Contratante com no mínimo 48 horas de antecedência; e (vi)
            manutenções emergenciais não superiores a 2 (duas) horas ou que
            ocorram no período das 24:00h até às 6:00h.
          </p>
          <p>
            O usuário será responsável pela contratação e manutenção de: (i)
            equipamento de TI adequado para o funcionamento do Software; e (ii)
            insumos de internet e energia elétrica para que seja possível
            acessar o servidor da TAXCEL. Em nenhuma hipótese a TAXCEL será
            responsabilizada por qualquer ou falha ou interrupção relacionada
            aos itens (i) e (ii) descritos nesta Cláusula.
          </p>

          <h2>Do Suporte</h2>
          <p>
            A TAXCEL oferecerá suporte técnico remoto por meio de telefone ou
            e-mail ao usuário. O Suporte estará disponível ao usuário de segunda
            a sexta-feira, das 9h00 às 18h00. O Suporte Técnico é limitado a
            esclarecimentos e informações acerca do Software e sua utilização.
          </p>

          <p>
            Qualquer outro tipo de suporte técnico, incluindo, mas não se
            limitando a suportes locais, instalação, migração ou consultoria
            deverão ser feitos nos termos e condições estipulados pela TAXCEL em
            cada caso.
          </p>

          <h2>Condições Gerais</h2>
          <p>
            As partes se comprometem a manter e tratar como confidencial, e não
            revelar a terceiros qualquer Informação Confidencial relacionada ao
            Software, a esta Proposta e aos serviços, dados de usuários, segredo
            de indústria e outros.
          </p>
          <p>
            As partes se comprometem a cumprir a Lei 13.709/18 (Lei de Proteção
            de Dados Pessoais - LGPD), bem como regras e regulamentos que lhe
            forem aplicáveis e que tenham por objeto os dados pessoais tratados
            no âmbito dos serviços contratados.
          </p>
          <p>
            Caberá exclusivamente ao usuário responsabilizar-se pela adequação,
            conformidade e cumprimento da legislação no tocante à autorização do
            tratamento dos dados pessoais disponibilizados pelo usuário no
            Software da TAXCEL. Em relação a estes dados, a TAXCEL se enquadra
            como Operadora de dados.
          </p>
          <p>
            Cada Parte será responsável pelo tratamento de dados pessoais por
            ela realizado, mantendo a outra Parte indene de danos ou prejuízos
            decorrentes de qualquer operação de tratamento de dados pessoais
            realizada em desacordo com o Contrato e/ou a legislação aplicável.
          </p>

          <h2>Modificações dos Termos de Uso</h2>
          <p>
            A TAXCEL se reserva o direito de modificar os Termos e Condições do
            presente documento a qualquer momento, por seu exclusivo critério,
            sendo consideradas efetivadas as alterações após a publicação de uma
            versão atualizada destes Termos no Software, o que se sujeitará ao
            aceite do usuário.
          </p>
          <br />
          <p>
            <b>Documento atualizado em outubro de 2023</b>
          </p>
        </Container>
      </Politica>
    </Layout>
  );
}
